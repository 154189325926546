/* eslint-disable camelcase */
import { Divider, Header } from 'semantic-ui-react';
import classNames from 'classnames';
import styleFilter from '../../lib/styleFilter';

const TextOnlyBody = ({
  clickable, title, as, subtitle, useBody,
}) => (
  <>
    <div className={classNames('HomeMainItem', { clickable })}>
      {title && <Header className="PostHeader" as={as} content={title} />}
      {subtitle && (
        <Header className="PostSubheader" as="h1" content={subtitle} />
      )}
      <div className="PostBody" dangerouslySetInnerHTML={{ __html: useBody }} />
    </div>
    <Divider />
  </>
);

export default function HomeMainItem({
  title,
  image,
  imageAlign,
  body,
  subtitle,
  // footer,
  clickable,
  as = 'h2',
}) {
  let useBody = body;
  try {
    useBody = styleFilter(body);
  } catch (e) {
    // noop
  }

  if (!image) {
    return (
      <TextOnlyBody {...{
        title,
        subtitle,
        image,
        imageAlign,
        useBody,
        clickable,
        as,
      }}
      />
    );
  }

  const imgFloat = (imageAlign ?? 'RIGHT').toLowerCase();

  const IMAGE_MAX_WIDTH = 30;
  const imgStyles = [
    `float: ${imgFloat}`,
    `max-width: ${IMAGE_MAX_WIDTH}%`,
  ];

  if (imgFloat === 'left') {
    imgStyles.push('margin-right: 1.5rem');
  }

  const theImage_or_Imaginer = `<img src="${image}" style="${imgStyles.join(';')}" alt="${subtitle}" />`;
  const finalBody = `<div>${theImage_or_Imaginer}${useBody}</div>`;

  return (
    <>
      <div className={classNames('HomeMainItem', { clickable })}>
        {title && <Header className="PostHeader" as={as} content={title} />}
        {subtitle && (
          <Header className="PostSubheader" as="h1" content={subtitle} />
        )}
        <div
          className="PostBody"
          dangerouslySetInnerHTML={{ __html: finalBody }}
        />
      </div>
      <div style={{ clear: 'both' }} />
      {/* clear the floats to prevent visual wonk */}
      <Divider />
    </>
  );
}
