import {
  Container,
  Embed,
  Icon,
  List,
  Modal,
  Button,
  Grid,
  Divider,
  Label,
  Segment,
} from 'semantic-ui-react';
import Link from 'next/link';
import { useState } from 'react';
import { format } from 'date-fns';
// import Image from 'next/image';
import { useRouter } from 'next/router';
import Layout from '../components/FluidLayout';
import styleFilter from '../lib/styleFilter';
import HomeMainItem from '../components/cards/HomeMainItem';

const fmt = (d) => format(new Date(d.replace(' ', 'T')), 'MMM do, yyyy');

export default function Index({
  events = [],
  features = [],
}) {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const noEvents = (
    <List.Item>
      <List.Content>
        <List.Header className="eventTitle">
          No Events Found
        </List.Header>
        <List.Description>
          <p style={{ color: 'white' }}>Check back soon!</p>
        </List.Description>
      </List.Content>
    </List.Item>
  );

  return (
    <Layout className="HomeLayout RedesignHome">
      <Container id="splashToo" textAlign="center" fluid>
        <Button
          id="findSpecialist"
          icon="search"
          size="huge"
          color="orange"
          content="Find a CLT-LANA Certified Specialist"
          style={{
            marginTop: '120px',
            padding: '20px',
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
          }}
          onClick={() => router.push('/therapists')}
        />
      </Container>

      <Container>
        <Modal
          open={open}
          size="large"
          dimmer="blurring"
          className="videoContainer"
        >
          <Modal.Content>
            <Embed
              id="_Vy1oMDkvdQ"
              autoplay
              controls
              width="100%"
              source="youtube"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="teal" onClick={() => setOpen(false)}>
              <Icon name="close" /> Close
            </Button>
          </Modal.Actions>
        </Modal>

        <Grid stackable>
          <Grid.Row />
          <Grid.Row divided>
            <Grid.Column computer={12} mobile={16} className="LeftColumn">
              <div>
                {features.map((feature) => (
                  <HomeMainItem
                    key={feature.id}
                    image={feature.image}
                    as="h1"
                    title={feature.title}
                    subtitle={feature.spotlight}
                    date={feature.last_updated}
                    imageAlign={feature.image_align}
                    body={`<p>${feature.body}</p>`}
                  />
                ))}
              </div>
            </Grid.Column>

            <Grid.Column computer={4} mobile={16} className="RightColumn">
              <Segment padded color="teal">
                <Link href="/calendar">
                  <Label attached="top" size="large" color="teal" className="clickable">
                    <span>Upcoming Events</span>
                  </Label>
                </Link>
                <List divided relaxed="very">
                  {events.map((event) => (
                    <List.Item key={event.id} href={`/calendar/${event.id}`}>
                      <List.Content>
                        <List.Header
                          title={event.name}
                          className="eventTitle"
                        >
                          <a href={`/calendar/${event.id}`}>
                            {styleFilter(event.name)}
                          </a>
                        </List.Header>
                        <List.Description>
                          <span className="dateRange">
                            {fmt(event.startdate)} - {fmt(event.enddate)}
                          </span>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  ))}

                  {events.length === 0 && noEvents}
                </List>
              </Segment>

              <Segment padded color="teal" className="clickable">
                <Label attached="top" size="large" color="teal">
                  Member Discounts
                </Label>

                <div>
                  <Link href="/ce-providers-promo">
                    <span>Check out the latest member discounts from our sponsors!</span>
                  </Link>
                </div>
              </Segment>

              <Segment padded color="teal">
                <Label attached="top" size="large" color="teal">
                  Online Learning Center
                </Label>

                <div>
                  <Divider hidden />

                  <p>
                    Check out our extensive repository of up-to-date useful
                    information on lymphedema for including, articles, videos,
                    product information, and LANA research right at your
                    fingertips.
                    <Divider hidden />
                    <Link href="/learning-center">
                      <span className="ui button fluid teal">
                        Visit the learning center.
                      </span>
                    </Link>
                  </p>
                </div>
              </Segment>
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </Container>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const events = await context.req.inlineQuery(
    'SELECT * FROM event WHERE startdate > NOW() ORDER BY startdate ASC LIMIT 2',
    1800,
  );
  const [featuredTherapist] = await context.req.inlineQuery(
    'SELECT * FROM user WHERE id = 357',
  );
  const features = await context.req.inlineQuery(
    'SELECT * FROM feature WHERE active = 1 ORDER BY `order` ASC',
  );
  return {
    props: {
      events,
      features,
      featuredTherapist,
    },
  };
}
